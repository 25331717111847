<template>
  <el-container class="forget-pasword-page">
    <el-row class="title" :gutter="20">
      <el-col :span="3" :offset="2">
        <img
          src="https://oss.sokogate.com/image/af82b848785b079390200737ab15cdff.png"
          alt=""
        />
      </el-col>
      <el-col :span="16" :offset="2" class="welcome">{{
        $t("login.retrievePassword")
      }}</el-col>
      <el-col :span="2">
        <languag-switch />
      </el-col>
    </el-row>
    <el-row class="building" :gutter="20">
      <el-col :span="12" class="forget-pasword-box">
        <el-card
          v-show="isShow"
          class="forget-password-card"
          v-loading="SengcodeLoading"
        >
          <div slot="header" class="clearfix">
            <span>{{ $t("login.retrievePassword") }}</span>
          </div>
          <sui-form
            ref="form"
            :list="formListToSengcode"
            :defaultdata="formDataToSengcode"
            @submit="submitToSengcode"
          />
          <p class="bd-box">
            <span class="toregiater" @click="login">
              {{ $t("login.backToLogin") }}
            </span>
          </p>
        </el-card>
        <el-card v-show="!isShow" class="forget-password-card">
          <sui-form
            ref="form"
            :list="formListToforget"
            :defaultdata="formDataToforget"
            @submit="submitToforget"
            :loading="formLoading"
          />
          <p class="bd-box">
            <span class="toregiater" @click="login">
              {{ $t("login.backToLogin") }}
            </span>
            <span class="toregiater" @click="reSend">
              {{ $t("login.resendCodeText") }}
            </span>
          </p>
        </el-card>
      </el-col>
      <el-col :span="12" class="loginbg">
        <img src="https://oss.sokogate.com/static/login_page_bg.png" alt="" />
      </el-col>
    </el-row>
    <el-row class="bottom-list">
      <el-col :span="24" :offset="10">
        <LinkDivider :list="linkList" />
      </el-col>
    </el-row>
    <el-row class="bottom">
      <el-col :span="24" :offset="10"
        >{{ $t("login.copyright") }}{{ $t("login.sokoGates")
        }}<version-no name="Vendor_Web"></version-no
      ></el-col>
    </el-row>
  </el-container>
</template>

<script>
import LanguagSwitch from "@/components/LanguagSwitch";
import LinkDivider from "@/components/LinkDivider";
import SuiForm from "@/components/s-ui/form";
import VersionNo from "@/components/VersionNo";
import { Forget, AddVerifyCode } from "@/utils/api";
export default {
  components: {
    LanguagSwitch,
    LinkDivider,
    SuiForm,
    VersionNo,
  },
  data() {
    return {
      isShow: true,
      //获取验证码表单的内容
      formListToforget: [
        // 邮箱
        {
          type: "mail",
          name: "email",
          label: "login.email",
          rules: [
            {
              required: true,
              message: "login.email",
              trigger: "blur",
            },
            {
              type: "email",
              message: "login.emailreg",
              trigger: "blur",
            },
          ],
        },
        // 验证码
        {
          type: "input",
          name: "verifyCode",
          label: "login.verificationCode",
          rules: [
            {
              required: true,
              message: "login.verificationCodeRequired",
              trigger: "blur",
            },
          ],
        },
        // 密码
        {
          type: "password",
          name: "password",
          label: "login.password",
          rules: [
            {
              required: true,
              message: "login.passwords",
              trigger: "blur",
            },
            { eval: "validatePass", trigger: "blur" },
          ],
        },
        // 确认密码
        {
          type: "password",
          name: "confirmpassword",
          label: "login.confirmpassword",
          rules: [
            {
              required: true,
              message: "login.passwordregs",
              trigger: "blur",
            },
            { eval: "validatePass2", trigger: "blur" },
          ],
        },
      ],
      //获取验证码表单的参数
      formDataToforget: {
        email: "",
        verifyCode: "",
        password: "",
      },
      //忘记密码表单的内容
      formListToSengcode: [
        // 邮箱
        {
          type: "mail",
          name: "cond",
          label: "login.email",
          rules: [
            {
              required: true,
              message: "login.email",
              trigger: "blur",
            },
            {
              type: "email",
              message: "login.emailreg",
              trigger: "blur",
            },
          ],
        },
      ],
      //忘记密码表单的参数
      formDataToSengcode: {
        codeType: 1,
        userType: 3,
        cond: "",
      },
      linkList: [
        {
          title: "dashboard",
        },
        {
          title: "orders",
        },
        {
          title: "commodity",
        },
        {
          title: "shop",
        },
        {
          title: "subscriptions",
        },
        {
          title: "accounts",
        },
        {
          title: "store",
        },
        {
          title: "settings",
        },
        {
          title: "evaluation",
        },
        {
          title: "management",
        },
        {
          title: "personal",
        },
        {
          title: "settings",
        },
        {
          title: "shop",
        },
        {
          title: "decoration",
        },
        {
          title: "picture",
        },
        {
          title: "space",
        },
      ],
      formLoading: false,
      SengcodeLoading: false,
    };
  },
  methods: {
    // 重新发送验证码
    reSend() {
      this.isShow = true;
    },
    // 去登录
    login() {
      this.$router.push("/v2/login");
    },
    // 发送验证码的表单提交
    submitToSengcode(data) {
      this.SengcodeLoading = true;
      console.log("submitToSengcode", data);
      AddVerifyCode(data)
        .then((res) => {
          this.SengcodeLoading = false;
          console.log("AddVerifyCode-res", res);
          this.formDataToforget.email = data.cond;
          if (res.errcode === 0) {
            this.isShow = false;
          }
          this.$message({
            showClose: false,
            message: this.$t("login.sendCodeTips"),
            type: "success",
          });
        })
        .catch((err) => {
          console.log("AddVerifyCode-err", err);
          this.SengcodeLoading = false;
          if (err.errcode === 2102) {
            this.$message({
              showClose: false,
              message: err.errmsg,
              type: "warning",
            });
          } else if (err.errcode === 1902) {
            this.$message({
              showClose: false,
              message: err.errmsg,
              type: "warning",
            });
          } else {
            this.$message({
              showClose: false,
              message: err.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 忘记密码的表单提交
    submitToforget(data) {
      this.formLoading = true;
      console.log("submit:", data);
      this.formDataToSengcode.cond = this.formDataToforget.email;
      Forget(data)
        .then((res) => {
          this.formLoading = false;
          console.log("login-res:", res);
          this.$router.push("/v2/login");
        })
        .catch((err) => {
          console.log("login-err:", err);
          this.formLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.el-container {
  display: flex;
  flex-direction: column;
}
.forget-pasword-page {
  min-width: 1500px;
  max-width: 1500px;
  margin: 0 auto;

  .title {
    width: 100%;
    margin: 40px 0 0 100px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .welcome {
      font-size: 25px;
    }
  }
  .building {
    background-color: #23262f;
    width: 100%;
    height: 581px;
    position: relative;
    margin-top: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    .forget-pasword-box {
      .forget-password-card {
        position: absolute;
        top: 40px;
        left: 120px;
        width: 487px;
        min-height: 228px;
        border-radius: 10px;
        .clearfix {
          font-size: 30px;
        }
        .el-button {
          font-size: 15px;
          max-width: 440px;
          height: 51px;
          background: #ff0a0a;
          border-color: #ff0a0a;
          border-radius: 15px;
          color: #fff;
        }
        .bd-box {
          display: flex;
          justify-content: space-between;
          font-size: 13px;
          span {
            cursor: pointer;
            color: #ff0a0a;
          }
        }
      }
    }
    .loginbg {
      position: absolute;
      left: 700px;
      top: 30px;
      width: 731px;
      height: 512px;
    }
  }
  .bottom-list {
    margin: 30px 0 0 30px;
    .el-link--inner {
      font-size: 12px;
      color: gray;
    }
  }
  .bottom {
    margin: 10px 0 0 30px;
    font-size: 12px;
    color: gray;
  }
}
</style>